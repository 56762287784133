@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary: var(--boston-blue);
  --secondary: var(--alabaster);
  --main-text-color: var(--cod-gray);
  --list-title-color: var(--boston-blue);
  --list-company-color: var(--cod-gray);
  --list-loc-color: var(--cod-gray);
  --visited-color: var(--purple-heart);

  /* profile */
  --main-border-color: var(--mercury1);
  --custom-weight: 700;
  --page-text-color: var(--cold-gray);
  --page-text-color2: var(--jumbo);
  --main-border-radius: 6px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  background-color: #fff;
  min-height: 100vh;
  color: var(--cod-gray);
}
.container {
  max-width: 960px !important;
  margin: auto;
}
.hidden {
  display: none;
}

.main {
  padding: 60px 16px 0;
}
.prefoot {
  background: white url('/jc/footer-background.svg') repeat-x bottom center;
}

.prefoot-shadow {
  background: linear-gradient(180deg, #dfe0df 0%, #ffffff 100%);
}

@media (min-width: 1024px) {
  .main {
    padding: 60px 0 0;
  }
}

.arrow {
  display: block;
  width: 8px;
  height: 8px;
  border-top-width: 2px;
  border-right-width: 2px;
  border-left-width: 0px;
  border-bottom-width: 0px;
  border-style: solid;
  border-color: white;
  transform: rotate(45deg);
  transition: all 0.3s ease-in-out;
}

.list-button:hover .arrow {
  border-color: white !important;
}


.jc-shadow {
  box-shadow: 0px 0px 20px 0px rgba(29, 29, 29, 0.08);
}

.popup-bg-blur-jc {
  backdrop-filter: blur(5.699999809265137px);
  background: rgba(0, 0, 0, 0.64);
}

.homepage-header-background {
  background: white url('/jc/header-background.svg') repeat-x top center;
}

.signup-font1 {
  @media screen and (min-width: 150px) {
    font-size: 24px;
  }

  @media screen and (min-width: 352px) {
    font-size: 42px;
  }

  @media screen and (min-width: 452px) {
    font-size: 60px;
  }

  @media screen and (min-width: 552px) {
    font-size: 82px;
  }

  @media screen and (min-width: 768px) {
    font-size: 46px;
  }

  @media screen and (min-width: 986px) {
    font-size: 64px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 82px;
  }
}

.signup-font2 {
  @media screen and (min-width: 150px) {
    font-size: 15px;
  }

  @media screen and (min-width: 352px) {
    font-size: 26px;
  }

  @media screen and (min-width: 452px) {
    font-size: 37px;
  }

  @media screen and (min-width: 552px) {
    font-size: 51px;
  }

  @media screen and (min-width: 768px) {
    font-size: 28px;
  }

  @media screen and (min-width: 986px) {
    font-size: 40px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 51px;
  }
}

.signup-font3 {
  @media screen and (min-width: 150px) {
    font-size: 14px;
  }

  @media screen and (min-width: 352px) {
    font-size: 24px;
  }

  @media screen and (min-width: 452px) {
    font-size: 35px;
  }

  @media screen and (min-width: 552px) {
    font-size: 48px;
  }

  @media screen and (min-width: 768px) {
    font-size: 26px;
  }

  @media screen and (min-width: 986px) {
    font-size: 37px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 48px;
  }
}

.signup-font4 {
  @media screen and (min-width: 150px) {
    font-size: 7px;
    max-width: 152px;
  }

  @media screen and (min-width: 352px) {
    font-size: 9px;
    max-width: 266px;
  }

  @media screen and (min-width: 452px) {
    font-size: 13px;
    max-width: 379px;
  }

  @media screen and (min-width: 552px) {
    font-size: 18px;
    max-width: 519px;
  }

  @media screen and (min-width: 768px) {
    font-size: 10px;
    max-width: 291px;
  }

  @media screen and (min-width: 986px) {
    font-size: 14px;
    max-width: 405px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 19px;
    max-width: 519px;
  }
}

.signup-font5 {
  @media screen and (min-width: 150px) {
    font-size: 17px;
  }

  @media screen and (min-width: 352px) {
    font-size: 29px;
  }

  @media screen and (min-width: 452px) {
    font-size: 42px;
  }

  @media screen and (min-width: 552px) {
    font-size: 58px;
  }

  @media screen and (min-width: 768px) {
    font-size: 32px;
  }

  @media screen and (min-width: 986px) {
    font-size: 45px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 58px;
  }
}

.signup-font6 {
  @media screen and (min-width: 150px) {
    font-size: 6px;
    max-width: 152px;
  }

  @media screen and (min-width: 352px) {
    font-size: 8px;
    max-width: 266px;
  }

  @media screen and (min-width: 452px) {
    font-size: 11px;
    max-width: 379px;
  }

  @media screen and (min-width: 552px) {
    font-size: 15px;
    max-width: 519px;
  }

  @media screen and (min-width: 768px) {
    font-size: 8.5px;
    max-width: 291px;
  }

  @media screen and (min-width: 986px) {
    font-size: 12px;
    max-width: 405px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 15px;
    max-width: 519px;
  }
}

.signup-font7 {
  @media screen and (min-width: 150px) {
    font-size: 9px;
    max-width: 152px;
  }

  @media screen and (min-width: 352px) {
    font-size: 10px;
    max-width: 266px;
  }

  @media screen and (min-width: 452px) {
    font-size: 14px;
    max-width: 379px;
  }

  @media screen and (min-width: 552px) {
    font-size: 20px;
    max-width: 519px;
  }

  @media screen and (min-width: 768px) {
    font-size: 11px;
    max-width: 291px;
  }

  @media screen and (min-width: 986px) {
    font-size: 15px;
    max-width: 405px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 20px;
    max-width: 519px;
  }
}

.signup-font8 {
  @media screen and (min-width: 150px) {
    font-size: 11px;
  }

  @media screen and (min-width: 352px) {
    font-size: 20px;
  }

  @media screen and (min-width: 452px) {
    font-size: 29px;
  }

  @media screen and (min-width: 552px) {
    font-size: 41px;
  }

  @media screen and (min-width: 768px) {
    font-size: 22px;
  }

  @media screen and (min-width: 986px) {
    font-size: 31px;
  }

  @media screen and (min-width: 1200px) {
    font-size: 41px;
  }
}
